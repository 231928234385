<template>
  <UserPanel />
</template>

<script>
import UserPanel from '../components/inside/UserPanel'

export default {
  components: {
    UserPanel
  }
}
</script>
