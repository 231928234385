<template>
    <v-app id="inspire">
     <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app >
        <template v-slot:prepend>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img src="https://image.flaticon.com/icons/svg/149/149071.svg"></v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-group  >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="title">{{ user.username }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-account-tie</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Contactanos</v-list-item-title>
              </v-list-item>

            </v-list-group>
          </v-list>
        </template>

        <v-divider></v-divider>

        <v-list nav dense >
          <v-list-item @click="ir('/user')">
            <v-list-item-icon>
              <v-icon>dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item>

          <v-list-item @click="ir('/clientes')">
            <v-list-item-icon>
              <v-icon>insert_chart_outlined</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item>

        </v-list>
    </v-navigation-drawer>

      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="#003853" dark >
        <v-toolbar-title style="width: 300px" class="ml-0" >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <span class="hidden-sm-and-down"  > <b>Syhsoftware</b> </span>
          <!--class="hidden-sm-and-down" -->
        </v-toolbar-title>
      <!-- <v-text-field flat solo-inverted hide-details prepend-inner-icon="search" label="Search" class="hidden-sm-and-down"
        ></v-text-field> -->
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>apps</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>notifications</v-icon>
        </v-btn>

        <v-menu offset-y v-model="menu"  :close-on-content-click="false" :nudge-width="200" >
          <template v-slot:activator="{ on }">
            <v-btn icon large v-on="on">
              <v-avatar size="32px"  >
                <v-img src="https://image.flaticon.com/icons/svg/149/149071.svg" alt="Vuetify" >
                </v-img></v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <img src="https://image.flaticon.com/icons/svg/149/149071.svg" alt="John">
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> {{ user.username }} </v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="ir('/')">Inicio</v-btn>
              <v-btn color="primary" text @click="logout">Salir</v-btn>
            </v-card-actions>
        </v-card>
        </v-menu>
      </v-app-bar>

      <v-content>

          <router-view/>

      </v-content>

<!--
      <v-btn bottom color="pink" dark fab fixed right @click="dialog = !dialog" >
        <v-icon>add</v-icon>
      </v-btn>
   -->
  </v-app>

</template>

<script>

export default {
  props: {
    source: String
  },
  components: {
  },
  data: () => ({
    dialog: false,
    drawer: null,
    menu: false,
    notifi: [],
    access_comp: [],
    companys: []
  }),
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    inicio: function () {
      this.$router.push('/')
    },
    ir: function (url) {
      this.$router.push(url)
    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    },
    getAccessCompany: function () {
      this.$http.get('/company/access/user/get/')
        .then(resp => {
          this.access_comp = resp.data
          this.getCompanys(resp.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getCompanys: function (licComp) {
      for (var i = 0; i < licComp.length; i++) {
        // console.log(licComp[i].company)
        this.companys.push(licComp[i].company)
      }
    }
  },
  created: function () {

    // console.log(this.$store.state.user)
    // this.getAccessCompany()
    // this.ifParnert()
  }
}
</script>
